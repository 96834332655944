import React from 'react';
import SharedPage from './shared';

const IndexPage = ({ data }) => {
  return <SharedPage pageType="design" data={data} />;
};

export default IndexPage;

export const query = graphql`
  query guidelinesAccessibilityDesignIndexQuery(
    $tierOne: String = "Guidelines"
  ) {
    ...indexList
  }
`;
